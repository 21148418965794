* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;

    src: url(../src/static/fonts/Montserrat-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: bold;

    src: url(../src/static/fonts/Montserrat-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-style: bold;

    src: url(../src/static/fonts/Montserrat-ExtraBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-style: italic;

    src: url(../src/static/fonts/Montserrat-ExtraBoldItalic.ttf)
    format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 700;
    font-style: bold;

    src: url(../src/static/fonts/SFProDisplay-Bold.ttf) format('truetype');
    font-display: swap;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
